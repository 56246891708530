// 对象转 odata 过滤字符串
export const getOdataFilterStringFromObject = (
  filter = {},
  connection = 'and',
) => {
  const filterList = Object.keys(filter)
    .filter((key) => ![undefined, null].includes(filter[key]))
    .map(
      (key) =>
        `${key} eq ${
          typeof filter[key] === 'number' ? filter[key] : `'${filter[key]}'`
        }`,
    );

  if (filterList?.length) {
    return `$filter=${filterList.join(` ${connection} `)}`;
  }
  return '';
};

// 分页对象转 odata 字符串
export const getPageOdataString = ({page, pageSize} = {}) => {
  if (page === undefined || pageSize === undefined) {
    return '';
  }
  return `$skip=${page * pageSize}&$top=${pageSize}`;
};

export const attachOdataQueryString = (url, stringList = []) => {
  let resultUrl = url;
  const validStringList = stringList.filter(Boolean);
  if (validStringList.length) {
    const hasQuery = url.includes('?');
    resultUrl += hasQuery ? '&' : '?';
    resultUrl += validStringList.join('&');
  }

  return resultUrl;
};
